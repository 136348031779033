import ReportIcon from '@mui/icons-material/Report'
import { Button, CardActions, CardContent, Typography } from '@mui/material'
import Card from '~/src/components/mui-wrappers/Card'
import { useFirmPathResolver } from '~/src/hooks/router/useFirmPathResolver'
import { useRouterPush } from '~/src/hooks/router/useRouterPush'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useTranslations } from '~/src/hooks/useTranslations'
import routes from '~/src/router/enums/routes.enum'

const AuthRequired = () => {
  const muiTheme = useCustomMuiTheme()
  const { push } = useRouterPush()
  const { resolve } = useFirmPathResolver()
  const { t } = useTranslations()

  const goToLogin = () => {
    push(resolve(routes.LOGIN))
  }

  return (
    <Card
      sx={{
        mx: 'auto',
        mt: `calc(var(--eo-safe-area-top) + ${muiTheme.spacing(3)})`,
        width: '40%',
        textAlign: 'center',
        [muiTheme.breakpoints.down('md')]: {
          width: '80%'
        }
      }}
    >
      <CardContent>
        <ReportIcon color='error' sx={{ fontSize: '4rem' }} />
        <Typography>{t('auth.must_login')}</Typography>
      </CardContent>
      <CardActions>
        <Button
          variant='contained'
          disableElevation={true}
          onClick={goToLogin}
          sx={{ mx: 'auto' }}
        >
          {t('global.login')}
        </Button>
      </CardActions>
    </Card>
  )
}

export default AuthRequired

import { Box } from '@mui/material'
import { ReactNode } from 'react'
import TabsHeader, { TabsHeaderTabsProps } from '~/src/components/utils/layout/Tabs/TabsHeader'

interface Props {
  title: string,
  children: ReactNode,
  TabsProps: TabsHeaderTabsProps,
}

const TabsLayout = ({ title, children, TabsProps }: Props) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateAreas: '"header"' +
          '"content"',
        rowGap: 4
      }}
    >
      <TabsHeader
        title={title}
        TabsProps={TabsProps}
      />

      <Box
        className='tabs-layout__content'
        sx={{ gridArea: 'content' }}>
        {children}
      </Box>
    </Box>
  )
}

export default TabsLayout
